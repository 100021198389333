import './App.css';
import React, { createContext, useState } from 'react';
import ChatComponent from './components/ChatComponent';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  return (
    <ChatContext.Provider value={{ messages, setMessages }}>
      {children}
    </ChatContext.Provider>
  );
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ChatProvider>
          <ChatComponent />
          {/* Autres composants */}
        </ChatProvider>
      </header>
    </div>
  );
}

export default App;
