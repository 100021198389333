import React, { useState, useEffect } from 'react';

function ChatComponent() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [username, setUsername] = useState('');
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    let ws = new WebSocket('ws://localhost:8080');

    ws.onopen = () => {
      setIsConnected(true);
      setSocket(ws);
    };

    ws.onmessage = (event) => {
      setMessages(prevMessages => [...prevMessages, event.data]);
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
      setIsConnected(false);
    };

    ws.onclose = () => {
      console.log('WebSocket Connection Closed');
      setIsConnected(false);
    };

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const handleSendMessage = () => {
    if (isConnected && socket) {
      socket.send(`${username}: ${newMessage}`);
      setNewMessage('');
    } else {
      console.log('WebSocket is not connected');
    }
  };

  const canISend = () => {
    // The username cannot be empty
    if (username === "") {
      return false;
    }
    handleSendMessage();
    return true;
  }

  return (
    <div>
      {isConnected ? (
        <>
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
          <>
            <input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
            />
            <input
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Entrez votre message"
            />
            <button onClick={canISend}>Envoyer</button>
          </>
        </>
      ) : (
        <p>Connecting to WebSocket...</p>
      )}
    </div>
  );
}

export default ChatComponent;